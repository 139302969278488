import React from "react"
import { graphql } from "gatsby"

import Layout from "../layouts"
import 'uikit/dist/css/uikit.css'
import '../styles/shop.scss';
import CustomMarkdown from "../utils/customMarkdown";
import { BsFillTelephoneFill, BsInstagram, BsTwitterX } from "react-icons/bs"
import { CgLink } from "react-icons/cg";
import { FaFacebookF } from 'react-icons/fa'

import GetImgUrl from "../utils/customFunctions"

import { Helmet } from "react-helmet"

import Paragraph from "../components/paragraph/paragraph";
import CTABanner from "../components/cta-banner/cta-banner";
import Header from "../components/header/header"
import Banner from "../components/banner/banner"
import CTAList from "../components/cta-list/ctaList"
import HomeCards from "../components/home-cards/home-cards";
import Comparison from "../components/comparison/comparison";
import ImageCarousel from "../components/image-carousel/image-carousel";
import Button from "../components/button/button";


// const Header = loadable(() => import("../components/header/header"))
// const Banner = loadable(() => import("../components/banner/banner"))
// const Paragraph = loadable(() => import("../components/paragraph/paragraph"))
// const CTAList = loadable(() => import("../components/cta-list/ctaList"))
// const CTABanner = loadable(() => import("../components/cta-banner/cta-banner"))
// const Comparison = loadable(() => import("../components/comparison/comparison"))
// const HtmlEditor = loadable(() => import("../components/html-editor/html-editor"))
// const HomeCards = loadable(() => import("../components/home-cards/home-cards"))
// const Button = loadable(() => import("../components/button/button"))


export const query = graphql`
  query shopPagesQuery($id: Int) {
    strapiShops(strapiId: { eq: $id }) {
        PageComponents
        FacebookURL
        InstagramURL
        PhoneNumber
        Slug
        Title
        TwitterURL
        WebSite
        TextContent
        shop_category {
            Slug
        }
    }
  }
`;

const Shop = ({ data }) => {



    function getImgUrl(localFile___NODE) {

        var imgUrl = { imgName: null, basicUrl: null, fluid: null, gatsbyImageData: null }

        data.allFile.edges.map((item, index) => {

            if (item.node.id === localFile___NODE) {

                imgUrl.imgName = item.node.name ? item.node.name : null
                imgUrl.basicUrl = item.node.publicURL ? item.node.publicURL : null
                imgUrl.fluid = item.node.childImageSharp ? item.node.childImageSharp.fluid : null
                imgUrl.gatsbyImageData = item.node.childImageSharp ? item.node.childImageSharp.gatsbyImageData : null


            }
            return ("")

        })

        return (imgUrl)
    }




    return (
        <>
            <Layout SEOTitle={data.strapiShops.SEOTitle || data.strapiShops.Title} SEODescription={data.strapiShops.SEODescription} footerDisclaimer={data.strapiShops.FooterDisclaimer}>

                {/** List of meta properties coming from strapi that applies only for the primary pages */
                    <Helmet>
                        {data.strapiShops.MetaTags?.map((item, index) => {
                            return (
                                <meta key={`meta-key-` + index} name={item.MetaName} content={item.MetaContent} />
                            )
                        })}
                    </Helmet>
                }

                <div className="shop-container">
                    <div className="shop-content">
                        <div className="shop-header">
                            <div className="shop-contact">
                                <h3>{data.strapiShops.Title}</h3>
                                <div className="contact-links">
                                    {
                                        data.strapiShops.PhoneNumber ? <a className="link" href={`tel:${data.strapiShops.PhoneNumber}`}><BsFillTelephoneFill/> {data.strapiShops.PhoneNumber}</a> : ""
                                    }
                                    {
                                        data.strapiShops.WebSite ? <a className="link" href={data.strapiShops.WebSite}><CgLink /> {data.strapiShops.WebSite}</a> : ""
                                    }
                                    {
                                        data.strapiShops.FacebookURL ? <a className="link" href={data.strapiShops.FacebookURL}><FaFacebookF /> </a> : ""
                                    }
                                    {
                                        data.strapiShops.InstagramURL ? <a className="link" href={data.strapiShops.InstagramURL}><BsInstagram /></a> : ""
                                    }
                                    {
                                        data.strapiShops.TwitterURL ? <a className="link" href={data.strapiShops.TwitterURL}><BsTwitterX /></a> : ""
                                    }
                                </div>
                            </div>
                            <div className="shop-text-content">
                                {data.strapiShops.TextContent ? <p><CustomMarkdown children={data.strapiShops.TextContent}></CustomMarkdown></p> : ""}
                            </div>

                            {
                                data.strapiShops.shop_category?.Slug && data.strapiShops.shop_category.Slug === "to-let" ?

                                    <>
                                        <div className="to-let-shop-container">
                                            <div className="to-let-shop-content">
                                                <div className="uk-text-center uk-grid" uk-grid="true">
                                                    <form className=" uk-grid to-let-grid" uk-grid="true">

                                                        <div className="uk-width-1-3@m uk-width-1-1 column">
                                                            <label className="uk-form-label" htmlFor="form-stacked-text">Your name*</label>
                                                            <input className="uk-input" type="text" id="form-stacked-text" name="name" required/>
                                                        </div>

                                                        <div className="uk-width-1-3@m uk-width-1-1 column">
                                                            <label className="uk-form-label" htmlFor="form-stacked-text">Your email*</label>
                                                            <input className="uk-input" type="text" id="form-stacked-text" name="name" required/>
                                                        </div>
                                                        <div className="uk-width-1-3@m uk-width-1-1 column">
                                                            <label className="uk-form-label" htmlFor="form-stacked-text">Your contact number</label>
                                                            <input className="uk-input" type="text" id="form-stacked-text" name="name" />
                                                        </div>
                                                        <div className="uk-width-1-1 margin-top">
                                                            <div className="contact-send-btn">
                                                                <Button
                                                                    id={"sign-up-submit"}
                                                                    btnClass={"primary"}
                                                                    btnType={"primary"}
                                                                    label={'ENQUIRE NOW'}
                                                                    isSubmit={"false"}
                                                                    additionalClass={"full-width-btn"}
                                                                ></Button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </>


                                    : " "
                            }
                            {
                                data.strapiShops.PageComponents.map((item, index) => {


                                    switch (item.strapi_component) {
                                        case "page-components.header":
                                            
                                            //console.log(item.Background)

                                            return (
                                                <>
                                                    <Header key={index}
                                                        title={item.Title}
                                                        markdownTitle={item.MarkdownTitle}
                                                        subtitle={item.Subtitle}
                                                        secondarySubtitle={item.SecondSubtitle}
                                                        CTAText={item.CTALabel}
                                                        CTAUrl={item.CTAUrl}
                                                        CTA_nofollow={item.CTA_nofollow}
                                                        Disclaimer={item.Disclaimer}
                                                        Background={item.Background !== null ? GetImgUrl(item.Background.localFile___NODE).gatsbyImageData : ""}
                                                        HeaderHeight={item.HeaderHeight}
                                                    ></Header>
                                                </>

                                            )

                                        case "page-components.image-slider":
                                            var contentsList = []

                                            item.Picture.map((image, index) => {

                                                var imageUrl = GetImgUrl(image.Image.localFile___NODE).gatsbyImageData
                                                contentsList.push({
                                                    ALT: image.ALT,
                                                    Image: imageUrl,
                                                })
                                                return ("")
                                            })
                                            return (

                                                <ImageCarousel
                                                    key={index}
                                                    contents={contentsList}
                                                ></ImageCarousel>
                                            )
                                        case "page-components.comparison":
                                            return (

                                                <Comparison key={index}
                                                    title={item.Title}
                                                    step1={item.Step1}
                                                    step2={item.Step2}
                                                    step3={item.Step3}
                                                    image1={getImgUrl(item.Image1.localFile___NODE)}
                                                    image2={getImgUrl(item.Image2.localFile___NODE)}
                                                    image3={getImgUrl(item.Image3.localFile___NODE)}
                                                ></Comparison>


                                            )


                                        case "page-components.cta-banner":
                                            return (

                                                <CTABanner
                                                    key={index}
                                                    Title={item.Title}
                                                    Subtitle={item.Subtitle}
                                                    ctaUrl={item.CTAUrl}
                                                    ctaLabel={item.CTALabel}
                                                ></CTABanner>

                                            )

                                        case "page-components.banner":
                                            var imageUrl = GetImgUrl(item.Image.localFile___NODE).gatsbyImageData
                                            return (
                                                <Banner
                                                    key={index}
                                                    text={item.Text}
                                                    image={imageUrl}
                                                    imageAlt={item.Image.alternativeText}
                                                    dark={item.DarkBackground}
                                                    title={item.Title}
                                                    subtitle={item.Subtitle}
                                                ></Banner>

                                            )

                                        case "page-components.home-cards":
                                            var contentsList = []
                                            item.CTACard.map((CTAitem, index) => {
                                                var imageUrl = CTAitem.Image !== null ? getImgUrl(CTAitem.Image.localFile___NODE) : ""
                                                contentsList.push({
                                                    Title: CTAitem.Title,
                                                    URL: CTAitem.URL,
                                                    Image: imageUrl,
                                                    Description: CTAitem.Description,
                                                })
                                                return ("")
                                            })
                                            return (

                                                <HomeCards
                                                    key={index}
                                                    contents={contentsList}
                                                ></HomeCards>


                                            )

                                        case "page-components.paragraph":
                                            return (

                                                <Paragraph
                                                    key={index}
                                                    title={item.Title ? item.Title : ""}
                                                    subtitle={item.SubTitle ? item.SubTitle : ""}
                                                    text={item.Text}
                                                    bgColor={item.BackgroundColor || ""}

                                                ></Paragraph>
                                            )

                                        case "page-components.cta-list":
                                            var contentsList = []
                                            item.CTAList.map((CTAitem, index) => {
                                                var imageUrl = GetImgUrl(CTAitem.Image.localFile___NODE).gatsbyImageData
                                                contentsList.push({
                                                    Title: CTAitem.Title,
                                                    URL: CTAitem.URL,
                                                    URLLabel: CTAitem.URLLabel,
                                                    ImageURL: CTAitem.Image,
                                                    ImageURL: imageUrl,
                                                    Alt: CTAitem.Image.alternativeText,
                                                    Description: CTAitem.Description,
                                                    DescriptionCharLimit: CTAitem.DescriptionCharLimit
                                                })
                                                return ("")
                                            })
                                            return (

                                                <CTAList
                                                    key={index}
                                                    title={item.Title ? item.Title : ""}
                                                    subtitle={item.Subtitle}
                                                    contents={contentsList}
                                                    cardStyle={item.CardStyle}
                                                ></CTAList>


                                            )

                                        default:
                                            return ("")

                                    }
                                })
                            }


                        </div>
                    </div>
                </div>

            </Layout>

        </>

    )

}

export default Shop